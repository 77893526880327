import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
          <path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
         <path d="M1750 2235 c0 -25 -23 -35 -35 -15 -3 6 -11 10 -17 10 -6 0 -3 -12 7
-26 9 -14 15 -27 13 -28 -2 -1 -14 -7 -28 -13 -25 -12 -25 -12 8 -12 32 -1 33
-2 27 -33 l-6 -33 20 25 21 25 21 -25 20 -25 -6 33 c-6 31 -5 32 27 33 33 0
33 0 8 12 -14 6 -27 12 -29 14 -3 2 6 15 20 30 21 23 22 25 4 18 -50 -19 -55
-18 -55 9 0 14 -4 26 -10 26 -5 0 -10 -11 -10 -25z m25 -75 c-8 -13 -35 -13
-35 -1 0 5 3 16 6 25 5 14 8 14 21 1 8 -8 11 -19 8 -25z"/>
<path d="M1477 2214 c-11 -11 -8 -52 4 -59 5 -4 21 -4 35 -1 20 5 25 12 22 34
-2 20 -9 28 -28 30 -14 2 -29 0 -33 -4z"/>
<path d="M1345 2106 c-31 -31 -27 -46 7 -22 12 9 38 16 56 16 30 0 33 -2 27
-22 -14 -48 -76 -172 -105 -208 -17 -21 -30 -41 -30 -45 0 -21 169 156 170
178 0 5 9 22 21 39 30 41 16 46 -21 8 l-32 -33 7 34 c10 47 12 49 43 40 52
-14 130 -13 161 4 17 8 31 17 31 18 0 1 -35 2 -77 3 -43 0 -112 4 -155 8 -74
9 -77 8 -103 -18z"/>
<path d="M1810 2042 c0 -4 5 -13 11 -19 16 -16 14 -65 -4 -100 -27 -52 -107
-113 -212 -164 -141 -68 -258 -100 -382 -106 -57 -3 -103 -9 -103 -13 0 -8 60
-20 106 -20 19 0 38 -8 50 -21 22 -26 94 -79 105 -79 4 0 -7 22 -24 49 -17 27
-37 47 -43 44 -7 -2 -15 0 -18 5 -9 15 18 22 50 13 19 -5 31 -17 37 -37 9 -29
64 -94 79 -94 4 0 8 33 8 74 0 62 3 75 18 80 10 3 6 4 -8 3 -26 -2 -27 -5 -29
-69 -1 -29 -4 -36 -16 -32 -8 4 -15 11 -15 16 0 5 -9 23 -21 40 -12 16 -18 32
-15 35 11 12 79 32 88 27 4 -3 22 4 38 16 16 12 35 18 41 14 8 -4 9 -3 5 4 -5
8 1 12 15 12 24 0 169 81 189 105 14 17 40 20 40 5 0 -5 -6 -10 -13 -10 -8 0
-20 -18 -27 -39 -12 -38 -15 -40 -64 -46 -28 -4 -61 -13 -73 -21 -12 -8 -29
-14 -38 -14 -9 0 -14 -4 -10 -9 6 -11 35 -7 64 8 11 6 47 11 80 11 l61 0 0 33
c0 26 10 44 46 81 77 81 87 144 29 195 -37 32 -45 36 -45 23z m46 -128 c-8
-32 -43 -71 -53 -59 -5 4 -2 10 5 12 7 3 19 20 27 39 16 40 30 45 21 8z"/>
<path d="M1502 2018 c-16 -16 -15 -28 1 -28 25 0 37 -21 25 -44 -10 -19 -10
-20 6 -7 29 24 47 66 33 80 -14 14 -50 14 -65 -1z"/>
<path d="M1640 1873 c0 -42 3 -74 6 -70 5 5 7 24 13 117 1 9 10 16 21 16 15 0
21 -9 26 -36 9 -57 12 -58 13 -2 l1 52 -40 0 -40 0 0 -77z"/>
<path d="M1518 1833 c5 -100 12 -107 12 -15 0 69 1 72 23 72 22 0 24 -5 26
-57 3 -83 11 -79 11 5 l0 72 -38 0 -39 0 5 -77z"/>
<path d="M1390 1767 c0 -58 2 -66 14 -55 9 8 11 17 5 23 -23 23 -6 85 23 85
18 0 20 -6 16 -50 -1 -28 0 -50 5 -50 4 0 7 3 8 8 0 4 1 28 2 54 l3 46 -38 4
-38 4 0 -69z"/>
<path d="M1260 1724 c0 -29 4 -43 10 -39 6 4 8 15 5 25 -7 23 22 53 46 47 11
-3 19 -1 19 4 0 5 -18 9 -40 9 l-40 0 0 -46z"/>
<path d="M1665 1670 c-1 -3 -8 -23 -15 -45 -7 -22 -21 -52 -31 -67 -27 -38
-24 -42 19 -18 41 24 112 103 112 125 0 11 -11 14 -41 12 -22 -1 -42 -4 -44
-7z"/>
<path d="M1598 1659 c-10 -6 -18 -18 -18 -29 0 -22 -31 -80 -43 -80 -5 0 -8
15 -6 33 3 50 0 77 -10 77 -4 0 -9 -36 -10 -80 -1 -44 2 -80 7 -80 17 0 71 66
87 107 20 50 17 66 -7 52z"/>
<path d="M1370 1145 l0 -125 30 0 30 0 0 125 0 125 -30 0 -30 0 0 -125z m40
-1 c0 -67 -4 -103 -10 -99 -11 7 -14 188 -3 198 12 13 13 7 13 -99z"/>
<path d="M830 1140 l0 -120 30 0 c29 0 30 2 29 43 -1 37 -2 38 -8 13 -9 -42
-27 -43 -35 -1 -10 51 -5 155 8 162 11 7 80 5 109 -3 20 -6 24 -72 5 -107 -10
-20 -10 -21 5 -8 12 9 17 29 17 67 0 65 -12 74 -99 74 l-61 0 0 -120z"/>
<path d="M1540 1140 l0 -120 68 0 c40 0 73 5 80 12 16 16 16 200 0 216 -7 7
-40 12 -80 12 l-68 0 0 -120z m126 95 c19 -4 21 -12 23 -88 2 -67 -1 -87 -14
-97 -13 -11 -21 -11 -36 -2 -13 8 -19 9 -19 1 0 -5 -12 -9 -27 -7 -28 3 -28 3
-31 92 -2 49 -1 95 2 102 5 12 36 12 102 -1z"/>
<path d="M1641 1154 c-1 -43 1 -76 3 -73 7 7 10 138 4 145 -3 2 -6 -30 -7 -72z"/>
<path d="M1593 1140 c0 -36 2 -50 4 -32 2 17 2 47 0 65 -2 17 -4 3 -4 -33z"/>
<path d="M1920 1221 c0 -11 -6 -21 -12 -24 -10 -3 -10 -9 0 -26 6 -11 12 -45
12 -76 0 -43 4 -56 19 -65 33 -17 61 -12 61 10 0 22 -7 25 -30 10 -23 -15 -40
8 -40 57 0 24 -4 52 -10 62 -7 14 -4 23 10 36 20 18 21 18 39 -2 15 -16 17
-23 8 -32 -9 -9 -8 -11 6 -6 18 8 24 35 7 35 -5 0 -10 9 -10 20 0 16 -7 20
-30 20 -23 0 -30 -5 -30 -19z"/>
<path d="M1032 1188 c-7 -7 -12 -40 -12 -80 0 -56 3 -69 19 -78 30 -15 97 -12
115 6 24 24 20 44 -10 44 -26 0 -58 -23 -47 -34 4 -3 -5 -6 -18 -6 -14 0 -30
5 -37 12 -13 13 -17 114 -4 121 4 3 29 8 56 11 37 6 46 5 41 -5 -5 -7 -4 -10
2 -6 6 4 13 -5 16 -21 3 -15 1 -28 -4 -30 -15 -6 -10 -22 6 -22 19 0 21 70 3
88 -16 16 -110 16 -126 0z"/>
<path d="M1197 1180 c-3 -11 -1 -23 4 -26 5 -3 9 2 9 10 0 9 14 19 33 25 l32
9 -36 1 c-29 1 -38 -3 -42 -19z"/>
<path d="M1298 1190 c20 -8 22 -16 22 -80 0 -60 -2 -70 -16 -68 -10 2 -19 -3
-21 -9 -3 -9 6 -13 26 -13 l31 0 -1 90 -1 90 -31 -1 c-29 0 -30 -1 -9 -9z"/>
<path d="M1742 1183 c-16 -34 -14 -129 3 -147 18 -18 51 -21 60 -6 3 6 -5 10
-19 10 -34 0 -42 19 -36 87 5 55 7 58 35 64 l30 7 -32 1 c-22 1 -35 -5 -41
-16z"/>
<path d="M1825 1190 c3 -5 15 -10 26 -10 16 0 19 -7 19 -47 0 -67 -9 -93 -31
-93 -11 0 -19 -4 -19 -10 0 -5 14 -10 30 -10 l30 0 0 90 0 90 -31 0 c-17 0
-28 -4 -24 -10z"/>
<path d="M2030 1181 c-11 -21 -13 -93 -4 -128 7 -27 37 -38 61 -24 17 10 17
10 1 11 -10 0 -20 0 -23 0 -21 -2 -33 31 -29 76 7 68 9 72 41 77 27 4 27 4 -4
6 -23 1 -35 -5 -43 -18z"/>
<path d="M2110 1191 c0 -5 10 -11 23 -13 18 -2 23 -10 25 -47 5 -62 -4 -91
-28 -91 -11 0 -20 -4 -20 -10 0 -5 14 -10 30 -10 l30 0 0 90 0 90 -30 0 c-16
0 -30 -4 -30 -9z"/>
<path d="M1197 1133 c-11 -20 -5 -84 9 -98 17 -17 50 -20 59 -5 4 6 -5 10 -20
10 -29 0 -44 27 -41 73 2 28 0 33 -7 20z"/>
<path d="M957 1070 c-3 -11 -1 -18 4 -14 5 3 9 0 9 -5 0 -18 -19 -12 -30 10
-16 28 -23 18 -11 -15 8 -20 17 -26 41 -26 35 0 35 0 11 40 -17 28 -19 29 -24
10z"/>
<path d="M690 860 c0 -5 5 -10 10 -10 6 0 10 -18 10 -40 0 -29 4 -40 15 -40
11 0 15 11 15 40 0 29 4 40 15 40 11 0 15 -11 15 -40 0 -22 5 -40 10 -40 6 0
10 11 10 25 0 16 6 25 15 25 9 0 15 -9 15 -25 0 -14 5 -25 10 -25 6 0 10 5 10
12 0 9 3 9 12 0 19 -19 56 -14 63 8 13 40 -43 70 -63 34 -8 -15 -10 -15 -15 0
-4 9 -16 16 -27 16 -11 0 -20 7 -20 15 0 11 -12 15 -50 15 -27 0 -50 -4 -50
-10z"/>
<path d="M968 820 c4 -49 22 -72 22 -27 l1 22 18 -22 c20 -24 48 -32 34 -11
-4 7 -7 29 -8 48 0 33 -2 35 -35 38 l-35 3 3 -51z"/>
<path d="M1230 820 c0 -33 4 -50 13 -50 8 0 12 17 12 50 0 34 -4 50 -12 50 -9
0 -13 -17 -13 -50z"/>
<path d="M1390 856 c0 -10 -14 -16 -42 -18 -40 -3 -43 -5 -43 -33 0 -27 3 -30
36 -33 35 -3 36 -2 31 29 -3 22 -1 30 7 25 6 -4 11 -18 11 -32 0 -32 18 -31
22 1 4 35 22 36 26 3 2 -16 9 -28 17 -28 8 0 11 7 8 21 -4 12 0 25 8 30 12 8
12 11 -1 19 -10 6 -11 10 -2 10 6 0 12 5 12 10 0 16 -30 12 -36 -5 -4 -8 -13
-15 -21 -15 -13 0 -13 3 -3 15 10 13 9 15 -9 15 -12 0 -21 -6 -21 -14z"/>
<path d="M1670 855 c0 -16 -15 -19 -66 -16 -29 2 -32 0 -36 -33 -3 -25 0 -36
9 -36 7 0 13 11 13 25 0 32 27 34 32 3 2 -18 10 -24 38 -26 l35 -3 2 51 c1 37
-3 50 -13 50 -8 0 -14 -7 -14 -15z"/>
<path d="M1788 823 c-1 -37 -2 -40 -5 -15 -4 28 -8 32 -33 32 -34 0 -53 -26
-39 -52 8 -14 23 -18 74 -18 36 0 67 5 71 11 4 8 9 7 15 -2 11 -19 15 -11 22
44 5 38 4 47 -8 47 -10 0 -15 -11 -15 -32 -1 -27 -2 -30 -11 -15 -5 9 -19 17
-29 17 -11 0 -20 7 -20 15 0 8 -4 15 -10 15 -5 0 -11 -21 -12 -47z"/>
<path d="M2070 856 c0 -8 -10 -16 -22 -18 -18 -2 -23 -10 -23 -33 0 -28 3 -30
39 -33 32 -3 38 -1 33 12 -4 9 -2 16 2 16 5 0 11 -7 15 -15 3 -9 18 -15 36
-15 27 0 30 3 30 32 0 17 3 28 7 24 4 -4 8 -17 8 -29 0 -20 6 -22 63 -25 l62
-2 0 35 c0 33 -2 35 -35 35 -31 0 -36 -3 -41 -30 -4 -16 -10 -30 -15 -30 -12
0 -11 37 1 45 6 4 4 12 -6 22 -11 12 -18 13 -27 4 -6 -6 -26 -11 -44 -11 -21
0 -36 -6 -39 -15 -11 -27 -24 -16 -17 15 4 23 2 30 -11 30 -9 0 -16 -6 -16
-14z"/>
<path d="M1070 825 c-19 -23 -1 -50 36 -52 20 -1 22 0 7 4 -13 2 -23 9 -23 14
0 6 9 7 21 4 17 -6 20 -3 17 17 -4 28 -39 36 -58 13z"/>
<path d="M1150 819 c-15 -27 3 -49 41 -49 26 0 29 3 29 35 0 32 -2 35 -30 35
-19 0 -33 -7 -40 -21z"/>
<path d="M1491 822 c-13 -25 5 -52 34 -52 15 0 26 7 30 20 13 41 -44 70 -64
32z"/>
<path d="M1911 822 c-13 -25 5 -52 35 -52 14 0 23 4 19 10 -3 5 -12 7 -20 3
-8 -3 -15 0 -15 7 0 6 8 10 18 9 30 -3 33 0 22 21 -13 25 -46 26 -59 2z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
